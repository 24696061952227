import * as styles from "../product-single-page.module.css";

import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import CART, { Cart as CartType } from "../../../types/Cart";
import React, { useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import { CartItem } from "./CartItem";
import { CartOrderSummary } from "./CartOrderSummary";
import EmptyCart from "./EmptyCart";
import Loading from "../Helpers/Loading";
import REMOVE from "../../../types/Remove";
import UPDATE from "../../../types/Update";
import debounce from "lodash.debounce";
import { fetchAllProducts } from "../../queries/fetchAllProducts";
import { getProductPrice } from "../../utils/shopUtils";
import { useAppState } from "../context";
import useAuth from "../useAuth";

type ShoppingCartPageType = {};
type CartQuery = {
  cart?: CartType;
};

const Cart: React.FC<ShoppingCartPageType> = () => {
  // CART
  const { cart, setCart } = useAppState();
  const [initialLoad, setInitialLoad] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const { loggedIn, isUnlicensedUser, isCustomerDefault } = useAuth();

  const { loading } = useQuery<CartQuery>(CART, {
    skip: !initialLoad,
    onCompleted: ({ cart }) => {
      setCart(cart);
      setInitialLoad(false);
      setInitialLoadComplete(true);
    },
    fetchPolicy: "cache-and-network",
  });

  const [removeAllItems, { loading: removeItemLoading }] = useMutation(REMOVE, {
    onCompleted({ removeItemsFromCart: { cart } }) {
      setCart(cart);
      window.location.reload();
    },
  });

  const [updateQuantities, { loading: updateQuantityLoading }] = useMutation(
    UPDATE,
    {
      onCompleted({ updateItemQuantities: { cart } }) {
        setCart(cart);
        window.location.reload();
      },
    }
  );

  const debouncedUpdateQuantities = debounce((updatedQuantities: any) => {
    updateQuantities({ variables: updatedQuantities });
  }, 500);

  const handleUpdateQuantities = (updatedQuantities: any) => {
    debouncedUpdateQuantities(updatedQuantities);
  };

  const updateLoading = useMemo(() => {
    return updateQuantityLoading || removeItemLoading;
  }, [updateQuantityLoading, removeItemLoading]);

  const cartContentNodes = useMemo(() => {
    const nodes = cart?.contents.nodes || [];

    return nodes;
  }, [cart]);

  const productIdToShowBundle = useMemo(() => {
    const productsWithBundles = cartContentNodes.filter((item) =>
      item.product.node.crossSellProducts?.bundles?.map(
        (item: any) => item.bundleName
      )
    );

    if (!productsWithBundles || productsWithBundles.length === 0) {
      return undefined;
    }

    const highestPricedProduct = productsWithBundles?.reduce((max, current) => {
      const currentPrice = parseFloat(
        current.product.node.regularPrice?.replace("$", "")
      );
      const maxPrice = parseFloat(
        max.product.node.regularPrice?.replace("$", "")
      );
      return currentPrice > maxPrice ? current : max;
    });

    return highestPricedProduct?.product?.node?.databaseId;
  }, [cartContentNodes]);

  const isDysportWithQuantity5InCart = useMemo(() => {
    return (
      cart?.contents.nodes?.some((item) => {
        return (
          item.product?.node.name === "DYSPORT® 500u (Czech)" ||
          item.product?.node.name === "DYSPORT® 300u" ||
          item.product?.node.name === "DYSPORT® 500u (English/Korean)" ||
          item.quantity === 5
        );
      }) || false
    );
  }, [cart]);

  const allProducts = fetchAllProducts();

  const botulinumItems = cartContentNodes.some((item: any) => {
    const categories = item.product.node?.productCategories?.nodes.map(
      (node: any) => node.name
    );
    return categories?.includes("Botulinum Toxins");
  });

  let productIds: number[] = [];
  if (botulinumItems) {
    productIds = [50159, 50170];
  } else if (!loggedIn || isUnlicensedUser || isCustomerDefault) {
    productIds = [5795, 5779, 5627, 5567];
  } else {
    productIds = [5567];
  }

  const randomProductId = useMemo(() => {
    return productIds[Math.floor(Math.random() * productIds.length)];
  }, [botulinumItems, loggedIn, isUnlicensedUser, isCustomerDefault]);

  const product = allProducts.find(
    (product: any) => product.databaseId === randomProductId
  );

  const [productQuantity, setProductQuantity] = useState(1);

  const suggestedPriceToShow = getProductPrice(
    productQuantity,
    product?.tierPrice,
    product?.price
  );

  const handleRemoveAllItems = (item: any) => {
    // Google Analytics event for removing items from the cart
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_name: item.product.node.name,
            item_id: item.product.node.databaseId,
            price: item.product.node.price
              ? parseFloat(item.product.node.price?.replace("$", ""))
              : undefined,
            item_brand: item.brand,
            item_category: item.product.node.productCategories,
            quantity: item.quantity,
          },
        ],
      },
    });

    removeAllItems({
      variables: {
        input: {
          clientMutationId: `1234`,
          all: false,
          keys: item.key,
        },
      },
    });
  };

  const shouldShowBundle = (item: any) => {
    const isSimpleProduct = !item.variation;
    const isStandardVariation = item.variation?.node?.name
      .toLowerCase()
      .includes("standard");

    return (
      item.product.node.databaseId === productIdToShowBundle &&
      (isSimpleProduct || isStandardVariation)
    );
  };

  if (loading || !cart) {
    return <Loading />;
  }
  if (!initialLoadComplete && loading) {
    return <Loading />;
  }
  if (cart?.contents.itemCount === 0) {
    return <EmptyCart />;
  }

  return (
    <Box
      bg="white"
      width="100%"
      minH="100vh"
      mx="auto"
      px={{ base: "4", md: "8", lg: "8em" }}
      py={{ base: "32", md: "32", lg: "32" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ lg: "flex-start" }}
        spacing={{ base: "8", md: "16" }}>
        <Stack spacing={{ base: "8", md: "6" }} flex="1">
          <Heading as="h1" fontSize="2xl" fontWeight="extrabold">
            Shopping Cart ({cart?.contents.itemCount} item
            {cart?.contents.itemCount > 1 && "s"})
          </Heading>
          {cartContentNodes.map((item) => {
            const priceToShow = getProductPrice(
              item?.quantity,
              item.product?.node?.tierPrice,
              item.product?.node?.price
            );

            const productName =
              item.variation?.node.name || item.product?.node.name;

            const adjustedPrice =
              item.product?.node?.promotions?.onPromotion == true
                ? item.product.node.price
                : Number(priceToShow);

            let newPrice = priceToShow;

            if (item.product?.node?.promotions?.onPromotion) {
              newPrice = item.product.node.price;
            } else if (
              item.variation?.node?.name.toLowerCase().includes("short dated")
            ) {
              newPrice = item.variation?.node?.price || priceToShow;
              // newPrice = priceToShow;
            } else if (item.variation == null) {
              newPrice = priceToShow;
            }

            return (
              <CartItem
                onClickDelete={() => handleRemoveAllItems(item)}
                onChangeQuantity={(qty: any) =>
                  handleUpdateQuantities({
                    key: item.key,
                    quantity: qty,
                  })
                }
                databaseId={item.product.node.databaseId}
                key={item.key}
                name={productName.slice(0, 16) + "..."}
                variationName={item.variation?.node.name}
                price={newPrice}
                quantity={item.quantity}
                imageUrl={item.product.node.image?.sourceUrl}
                altText={item.product.node.image?.altText}
                productTags={item.product.node.productTags}
                slug={`/${item.product.node.slug}`}
                categories={item.product.node.productCategories}
                gratis={item.gratis}
                updateLoading={updateLoading}
                bundles={
                  shouldShowBundle(item)
                    ? item.product.node.crossSellProducts.bundles
                    : null
                }
                productName={productName}
                products={allProducts}
                stockQuantity={
                  item.variation?.node.stockQuantity || 10000000000000000
                } // Pass stockQuantity
              />
            );
          })}
        </Stack>

        <Flex direction="column" align="center" flex="1">
          <CartOrderSummary
            subtotal={cart.subtotal}
            total={cart.total}
            shippingTotal={cart.shippingTotal}
            discountTotal={cart.discountTotal}
            appliedCoupons={cart.appliedCoupons}
            updateLoading={updateLoading}
          />
        </Flex>
      </Stack>

      <VStack
        alignItems="flex-start"
        gap="24px"
        w={{ base: "100%", lg: "50%" }}
        py="86px">
        <Text as="h2" className={styles.productsYouMay}>
          Suggested for you:
        </Text>
        <CartItem
          isSuggested
          onChangeQuantity={(qty: any) => setProductQuantity(qty)}
          databaseId={product?.databaseId}
          key={product?.key}
          name={product?.name}
          variationName={product.variation?.node?.name}
          price={suggestedPriceToShow}
          quantity={product?.quantity}
          imageUrl={product?.image?.sourceUrl}
          productTags={product?.productTags}
          slug={`/${product?.slug}`}
          categories={product?.productCategories}
          gratis={product?.gratis}
        />
      </VStack>
    </Box>
  );
};

export default Cart;
