import { CartProduct } from './CartProduct';
import { gql } from '@apollo/client';

export type Cart = {
	subtotal?: string;
	total?: string;
	shippingTotal?: string;
	discountTotal?: string;
	appliedCoupons?: string[];
	upsellProducts: string;
	relatedProducts: string;
	product: string;
  variation: string;
  gratis: number;
	contents: {
		itemCount: number;
		nodes: Array<{
      variation: any;
      gratis: number;
			total: string;
			subtotal: string;
			slug: any;
			key: string;
			quantity: number;
			product: { node: CartProduct };
		}>;
	};
};

const CART = gql`
  query Cart {
    cart(recalculateTotals: true) {
      subtotal
      total
      shippingTotal
      discountTotal
      appliedCoupons {
        code
      }
      contents {
        itemCount
        nodes {
          quantity
          key
          product {
            node {
              id
              name
              sku
              slug
              databaseId
              crossSellProducts {
                bundles {
                  bundleName
                  bundleProducts {
                    productSku
                  }
                }
              }
              productCategories {
                nodes {
                  name
                }
              }
              image {
                sourceUrl
              }
              productTags {
                nodes {
                  name
                }
              }

              promotions {
                onPromotion
              }
                ... on VariableProduct {
                regularPrice
                price
                salePrice
                stockQuantity
                tierPrice {
                  tierPrice {
                    price
                    price2
                    price3
                    price4
                  }
                }
              }
              ... on SimpleProduct {
                related {
                  relatedProducts: nodes {
                    name
                    slug
                    databaseId
                    description

                    image {
                      sourceUrl
                    }
                    productTags {
                      nodes {
                        name
                      }
                    }
                    ... on SimpleProduct {
                      id
                      name
                      price
                      regularPrice
                      onSale
                      stockStatus
                    }
                  }
                }
                upsell {
                  upsellProducts: nodes {
                    name
                    slug
                    databaseId
                    description

                    image {
                      sourceUrl
                    }
                    productTags {
                      nodes {
                        name
                      }
                    }
                    ... on SimpleProduct {
                      id
                      name
                      price
                      regularPrice
                      onSale
                      stockStatus
                    }
                  }
                }
                regularPrice
                price
                salePrice
                tierPrice {
                  tierPrice {
                    price
                    price2
                    price3
                    price4
                  }
                }
              }
            }
          }

          variation {
            node {
              name
              price
              stockQuantity
                tierPrice {
                  tierPrice {
                    price
                    price2
                    price3
                    price4
                  }
                }
            }
          }
        }
      }
    }
  }
`;
export default CART;